<template>
    <svg v-if="active" viewBox="0 0 25 27" fill="none">
        <path d="M2.25 6H3.636C4.146 6 4.591 6.343 4.723 6.835L5.106 8.272M5.106 8.272C10.6766 8.11589 16.2419 8.73515 21.642 10.112C20.818 12.566 19.839 14.95 18.718 17.25H7.5M5.106 8.272L7.5 17.25M7.5 17.25C6.70435 17.25 5.94129 17.5661 5.37868 18.1287C4.81607 18.6913 4.5 19.4544 4.5 20.25H20.25M6 23.25C6 23.4489 5.92098 23.6397 5.78033 23.7803C5.63968 23.921 5.44891 24 5.25 24C5.05109 24 4.86032 23.921 4.71967 23.7803C4.57902 23.6397 4.5 23.4489 4.5 23.25C4.5 23.0511 4.57902 22.8603 4.71967 22.7197C4.86032 22.579 5.05109 22.5 5.25 22.5C5.44891 22.5 5.63968 22.579 5.78033 22.7197C5.92098 22.8603 6 23.0511 6 23.25ZM18.75 23.25C18.75 23.4489 18.671 23.6397 18.5303 23.7803C18.3897 23.921 18.1989 24 18 24C17.8011 24 17.6103 23.921 17.4697 23.7803C17.329 23.6397 17.25 23.4489 17.25 23.25C17.25 23.0511 17.329 22.8603 17.4697 22.7197C17.6103 22.579 17.8011 22.5 18 22.5C18.1989 22.5 18.3897 22.579 18.5303 22.7197C18.671 22.8603 18.75 23.0511 18.75 23.25Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <circle cx="19" cy="6" r="6" :fill="activeColor"/>
    </svg>

    <svg v-else fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" ><path stroke-linecap="round" stroke-linejoin="round" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
</template>

<script>
export default {
    name: "ShoppingCartOutline",
    props: {
        active: {
            type: Boolean
        },
        activeColor: {
            type: String,
            default: '#F56E58'
        }
    }
}
</script>
